import MarkdownIt from 'markdown-it'

// Cached markdown instance
let md: MarkdownIt
const getMd = () => {
  if (!md) {
    md = new MarkdownIt()
    const defaultRender =
      md.renderer.rules.link_open ||
      function (tokens, idx, options, _env, self) {
        return self.renderToken(tokens, idx, options)
      }

    // Examples of component replaces https://github.com/miaolz123/vue-markdown/issues/104#issuecomment-1501309860
    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
      if (!tokens[idx].attrGet('href')?.startsWith('/')) {
        tokens[idx].attrPush(['target', '_blank'])
        tokens[idx].attrPush(['rel', 'nofollow noopener noreferrer'])
      }
      return defaultRender(tokens, idx, options, env, self)
    }
  }
  return md
}

export const mdToHtml = (mdText: string) => {
  const md = getMd()
  return md.render(mdText)
}
